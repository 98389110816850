<template>
  <div id="promotion">
    <div id="invite-bg">
      <img src="../../assets/activation.png" />
    </div>
    <div class="bg-wrap">
      <div class="logo"><img src="../../assets/logo.png" /></div>
      <div class="desc">
        <div class="main">任天堂家庭会员拼车</div>
        <div class="sub">
          全网<span style="color: #EF6742;">口碑爆棚</span>的任天堂会员拼车平台
        </div>
      </div>
      <div class="qr-code">
        <div class="qr-code-img">
          <img src="../../assets/official.png" />
        </div>
        <div class="scan-desc">微信扫码（长按保存识别）</div>
        <div class="code-desc">
          <div class="text">
            <div class="number">1</div>
            关注「youthswitch」官方公众号
          </div>
          <div class="text">
            <div class="number">2</div>
            点击公众号下方菜单「会员拼车」
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'promotion',
  data () {
    return {
    }
  },
  components: {
  },
  methods: {
  },
  created () {}
}
</script>

<style lang="less" scoped>
#promotion {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 7.5rem;
  background: #f2f3e9;
  font-family: PingFangSC-Medium, PingFang SC;
  position: relative;
  #invite-bg {
    position: absolute;
    top: 0;
    width: 7.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .bg-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: scroll;
    .logo {
      margin-top: 1.5rem;
      height: 0.56rem;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .desc {
      margin-top: 0.56rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.4rem;
      .main {
        font-size: 0.56rem;
        font-weight: 500;
        color: #339886;
      }
      .sub {
        letter-spacing: 0.03rem;
        color: #339886;
        font-size: 0.28rem;
        font-weight: 500;
        margin-top: 0.12rem;
      }
    }

    .qr-code {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 6rem;
      z-index: 2;
      color: #424242;
      font-size: 0.28rem;
      margin-bottom: 1rem;
      .qr-code-img {
        width: 3.26rem;
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
      .code-desc {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 0.36rem;
        justify-content: center;
        .text {
          width: 100%;
          display: flex;
          margin-bottom: 0.24rem;
          align-items: center;
          height: 0.4rem;
          .number {
            color: #fff;
            height: 0.4rem;
            width: 0.4rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            margin-right: 0.20rem;
            justify-content: center;
            background: #fc9d62;
            font-size: 0.28rem;
          }
        }
      }
    }
  }
}
</style>
